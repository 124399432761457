import Image from "react-bootstrap/Image";
import logo from "./Images/KeenOnSkin-Logo.png";

function App() {
	return (
		<>
			<div className='container-fluid'>
				<header>
					<div>
						<div>
							<Image
								src={logo}
								alt='Vevy Switzerland Logo'
								className='img-responsive center-block'
							/>
						</div>
					</div>

					<div>
						<div>
							<h4>
								KEEN ON SKIN is a tool for scientific divulgation,
								<br /> supplement of Lexicon Vevy Europe Skin Care Instant
								Reports,
								<br /> based on specific skin topics.
								<br />
								<br />
								<small>
									For more information visit{" "}
									<a
										target='_blank'
										href='https://www.linkedin.com/showcase/keen-on-skin/ '
										title='KEEN ON SKIN on linkedin'
									>
										{" "}
										our Linkedin profile
									</a>
								</small>
							</h4>
						</div>
					</div>
					<br />
					<br />
					<div className='orangeBord horizzontal'>
						<div>
							Vevy Europe S.p.A.
							<br />
							via Semeria, 16A - 16131 Genova - Italia
						</div>
						<div>Tel +39 010 5225 1</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default App;
